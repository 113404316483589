<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>
        <!-- New Order -->
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-right justify-content-end mb-1 mb-md-0 topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'create-sell-order' })"
            >
              Nueva orden de venta
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr style="margin-top: 3px">
      <!-- Filters -->
      <b-row align-h="center" class="mt-2">
          <b-col lg="3" md="3" sm="12">
              <b-form-group
                      label="Fecha inicial"
              >
                  <flat-pickr
                          class="form-control d-inline-block ml-50 mr-1"
                          :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                          v-model="startDate"
                  />
              </b-form-group>
          </b-col>
          <b-col lg="3" md="3" sm="12">
              <b-form-group
                      label="Fecha final"
              >
                  <flat-pickr
                          class="form-control d-inline-block ml-50 mr-1"
                          :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                          v-model="endDate"
                  />
              </b-form-group>
          </b-col>
          <b-col lg="3" md="3" sm="12">
              <b-form-group
                      label="Cliente"
              >
                  <v-select
                          v-model="buyerCompanyId"
                          label="name"
                          :options="buyerCompanies"
                          class="select-size-m"
                          :reduce="option => option.companyId"
                  />
              </b-form-group>
          </b-col>
          <b-col lg="3" md="3" sm="12">
              <b-form-group>
                <b-button
                        class="mt-1 mb-1"
                        variant="primary"
                        @click="findSellOrdersApi()"
                        :disabled=disabledButton
                >
                    <feather-icon
                            icon="SearchIcon"
                            class="mr-50"
                    />
                    Buscar ordenes
                </b-button>
              </b-form-group>
          </b-col>
      </b-row>
    </div>

    <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="sellOrders"
          @row-clicked="$router.push({ name: 'edit-sell-order', params: { sellOrderId: $event.sellOrderId }})"
          ref="refInvoiceListTable"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- Column: IdOrder -->
          <template #cell(more)="data">
            <div class="font-weight-bold">
              Ver mas
            </div>
          </template>

          <!-- Column: Shipping Status -->
          <template #cell(shippingStatus)="data">
            <template v-if="data.item.shippingStatus === 'ENTREGADO'">
              <b-badge
                pill
                variant="light-success"
              >
                Entregado
              </b-badge>
            </template>
            <template v-else-if="data.item.shippingStatus === 'EN CURSO'">
              <b-badge
                pill
                variant="light-warning"
              >
                En curso
              </b-badge>
            </template>
            <template v-else-if="data.item.shippingStatus === 'SIN ENVIAR'">
              <b-badge
                pill
                variant="light-danger"
              >
                Sin enviar
              </b-badge>
            </template>
          </template>

          <!-- Column: Payment Status -->
          <template #cell(paymentStatus)="data">
            <template v-if="data.item.paymentStatus === 'PAGADO'">
              <b-badge
                pill
                variant="light-success"
              >
                Pagado
              </b-badge>
            </template>
            <template v-else-if="data.item.paymentStatus === 'PARCIALMENTE PAGADO'">
              <b-badge
                pill
                variant="light-warning"
              >
                Parcial
              </b-badge>
            </template>
            <template v-else-if="data.item.paymentStatus === 'SIN PAGAR'">
              <b-badge
                pill
                variant="light-danger"
              >
                Sin pagar
              </b-badge>
            </template>
          </template>

          <!-- Column: Creation Date -->
          <template #cell(createdAt)="data">
              {{ new Date(data.item.createdAt) | moment("DD/MM/YYYY - H:mm") }}
          </template>

          <template #cell(total)="data">
              <span>Total: ${{ formatNumber(data.item.total) }}</span>
          </template>

        </b-table>
      </div>
    </b-overlay>

    <div class="mx-2 mb-2" style="margin-top: 1em">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
        <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
</style>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    vSelect,
    BFormGroup,
    BFormCheckbox,
    flatPickr
  },
  data() {
    return {
      // Table
      tableColumns: [
        { key: 'shortSellOrderId', label: 'ID', sortable: true, thStyle: { width: '1em' } },
        { key: 'createdAt', label: 'Fecha de creación', sortable: true },
        { key: 'buyerCompanyName', label: 'Empresa', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'status', label: 'Estatus', sortable: true },
        { key: 'more', label: 'Detalles', sortable: false },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'status',
      sortDesc: false,
      sortDirection: 'asc',

      // Filters
      filtersArray: [null, null, null, false],
      filter: null,
      filterOn: [],
      onFiltered: [],
      companies: [],
      pointOfSales: [],

      // Data
      sellOrders: [],
      buyerCompanies: [],
      buyerCompanyId: undefined,
      user: JSON.parse(localStorage.getItem("user")),
      startDate: this.$moment().startOf('day').format('DD-MM-YYYY HH:mm'),
      endDate: this.$moment().endOf('day').format('DD-MM-YYYY HH:mm'),
      disabledButton: true,


      data: [],
      paymentTypes: [],

      // Props
      tableOverlay: true,
    }
  },
  created() {
    this.findCompaniesBuyerApi()
    this.findSellOrdersApi();
  },
  methods: {
    findSellOrdersApi() {
        this.tableOverlay =  true;
        this.disabledButton = true;
        this.$axios.post(this.$services + 'manager/findSellOrdersByViewSellOrdersFiltersApi', {
            buyerCompanyId: this.buyerCompanyId,
            sellerCompanyId: this.user.companyId,
            startDate: this.$moment(this.startDate, 'DD-MM-YYYY HH:mm').utc().format(),
            endDate: this.$moment(this.endDate, 'DD-MM-YYYY HH:mm').utc().format(),
        }).then(res => {
            if (res.data) {
                this.sellOrders = res.data;
                this.totalRows = res.data.length;
            }
        }).finally(() => {
            this.tableOverlay =  false;
            this.disabledButton = false;
        });
    },
    findCompaniesBuyerApi() {
        this.$axios
            .post(this.$services + 'manager/findCompaniesBuyerApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.buyerCompanies = res.data;
            })
    },
    findPosByCompanyIdApi() {
        this.$axios
            .post(this.$services + 'manager/findPosByCompanyIdApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.pointOfSales = res.data;
            })
    },
    formatNumber(value) {
        const number = this.$BigNumber(value);
        return number.toFormat(2);
    },
  },
}
</script>
